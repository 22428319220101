import $ from "jquery";

function getUrlParam(name: string, url?: string | null) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
export class mansionsPageController {
  form: JQuery<HTMLElement>;
  modal: JQuery<HTMLElement>;
  constructor() {
    const district_id = getUrlParam("district");
    this.form = $(".contactForm");
    this.modal = $("#modalAreaSelection");
    this.init_default_form_checkboxes(Number(district_id));
    this.setupHandlers();
  }

  init_default_form_checkboxes(district_id: number) {
    let districts = "";
    //チェックボックスを更新する

    $(`#cb-district-${district_id}`).prop("checked", true);
    //chipsを更新する
    const cb_checked = this.modal.find(".cb-district:checked");
    const chips_wrapper = this.form.find(".selection-area-list");
    this.renderChips(cb_checked, chips_wrapper);

    //contact form7用透明フィールドを更新する
    this.updateDistrictFormField();
  }

  //contact form 7 で処理する様の透明フィールドに選択された地域を入力する。
  updateDistrictFormField() {
    var areas_text = "";
    this.modal.find(".cb-district:checked").each(function (index, element) {
      areas_text += $(element).next().text() + ", ";
    });
    $('input:text[name="areas-and-stations"]').val(areas_text);
  }

  renderChips(selections: JQuery<HTMLElement>, wrapper: JQuery<HTMLElement>) {
    wrapper.empty();
    if ($(selections).length == 0) {
      wrapper.append("<p>選択してください</p>");
    }
    var list = selections
      .map(function () {
        wrapper.append("<span>" + $(this).next().text() + "</span>");
        return $(this).next().text();
      })
      .get();

    return list;
  }

  setupHandlers() {
    const accordionBtn = this.form.find("#accordionFormBtn");
    accordionBtn.on("click", () => {
      const content = this.form.find(".CA-accordion-form");
      if (content.hasClass("is-expand")) {
        content.removeClass("is-expand");
        accordionBtn.removeClass("open");
      } else {
        content.addClass("is-expand");
        accordionBtn.addClass("open");
      }
    });
  }
}
